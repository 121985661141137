import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
// import Socials from './Socials'

const ticks = [
    {
        highlight: null,
        text: "Full-Stack Software Engineer",
    },
    {
        highlight: `${(new Date().getFullYear()) - 2016}+ years`,
        text: " of work experience",
    },
    {
        highlight: null,
        text: "Backend, Front-End, Databases ...",
    },
    {
        highlight: "Interests:",
        text: " Software Engineering, Data Science & Analytics, AI & Machine Learning ...",
    },
];

const MainBanner = () => {
    return (
        <div id="about" className="banner-area border-bottom layout-section">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="banner-content">
                            <h1>Chijioke</h1>
                            <h1>Elisha-Wigwe</h1>

                            <div className="row align-items-center my-5">
                                <div className="col-lg-12">
                                    <div className="awards-inner">
                                        <ul className="align-items-center">
                                            {ticks.map((tick, i) => {
                                                let tickElement;

                                                if (tick.highlight) {
                                                    tickElement = (<><span className="highlight">{tick.highlight}</span> {tick.text}</>)
                                                } else {
                                                    tickElement = (<span>{tick.text}</span>)
                                                }
                                                return (<li key={i} className=" mb-2 d-inline-flex flex-row w-100 align-items-center">
                                                    <FontAwesomeIcon className="mr-4 main-color icon-disc flex-shrink-0 flex-grow-0" size="x" icon={faCircle} />
                                                    <h5 className="text-left d-inline-block">
                                                        {tickElement}
                                                    </h5>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <Socials size="1.5x" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </div>
    )
}

export default MainBanner