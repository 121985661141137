import React from "react"
import Layout from "../components/App/layout"
import SEO from "../components/App/seo"
import Navbar from "../components/Index/Navbar"
import bannerImg from '../components/App/assets/images/banner/main.jpg'
import Footer from "../components/Index/Footer"
import Socials from "../components/Index/Socials"
import navSections from "../components/Index/sections"

const IndexPage = () => (
  <Layout>
    <Navbar />
    <SEO title="Chijioke Roy" />
    <div className="main-area">
      <div className="main-left-img">
        <img src={bannerImg} alt="banner" />
        <span className="main-left title position-absolute text-center font-bolder w-100 text-white" size="2x">let's connect</span>
        <Socials className="main-left socials" size="2x" />
      </div>

      <div className="main-content">
        {navSections.map(({ component: Component }, i) => <Component key={i} /> )}
        <Footer />
      </div>
    </div>
  </Layout>
)

export default IndexPage
