import React from 'react'
// import CVLink from '../App/CVLink'
import Heartbeat from '../App/Heartbeat'

const Footer = () => {
    return (
        <React.Fragment>
            <div className="py-4">
                <div className="container d-flex justify-content-around align-items-center ">
                    <div className="justify-content-center d-flex">
                        <Heartbeat className="dark mr-2" /> <h6> Roy</h6>
                    </div>
                    {/* <CVLink /> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footer