import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaravel, faHtml5, faJs, faPhp, faNodeJs, faVuejs, faReact, faCss3, faPython, faWordpress, faDrupal } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faDatabase, faPlusCircle, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import SectionLayout from './SectionLayout';

const skills = [
    {
        name: "PHP",
        icon: faPhp,
        description: "",
    },
    {
        name: "Laravel/Lumen",
        icon: faLaravel,
        description: "APIs, Web Services, Backend Engineering, Scripting",
    },
    {
        name: "WordPress",
        icon: faWordpress,
        description: "APIs, Web Services, Backend Engineering, Scripting",
    },
    // {
    //     name: "Drupal",
    //     icon: faDrupal,
    //     description: "APIs, Web Services, Backend Engineering, Scripting",
    // },
    {
        name: "JavaScript",
        icon: faJs,
        description: "Front-End Engineering, SPAs, etc",
    },
    {
        name: "VueJS",
        icon: faVuejs,
        description: "Front-End Engineering, SPAs, etc",
    },
    {
        name: "ReactJS",
        icon: faReact,
        description: "Front-End Engineering, SPAs, etc",
    },
    {
        name: "NodeJS",
        icon: faNodeJs,
        description: "",
    },
    // {
    //     name: "Html5",
    //     icon: faHtml5,
    //     description: "HTML",
    // },
    // {
    //     name: "CSS/SCSS",
    //     icon: faCss3,
    //     description: "",
    // },
    {
        name: "Databases: MySQL PostgreSQL",
        icon: faDatabase,
        description: "",
    },
    {
        name: "Python for Data Science",
        icon: faPython,
        description: "",
    },
    // {
    //     name: "Others",
    //     icon: faCheck,
    //     description: "",
    // },
]

const Skills = () => {
    return (
        <SectionLayout header={{
            id: 'skills',
            classes: 'what-area',
            subtitle: 'WHAT I DO',
            title: 'Some Of My Skills',
            description: 'I have worked with different tools and frameworks, including:',
        }}>
            <div className="row">
                {skills.map((skill, i) => (
                    <div key={i} className="col-6 col-md-4">
                        <div className="what-item">
                            <FontAwesomeIcon className="skill-item mb-3" icon={skill.icon} size="4x" />
                            <h3>{skill.name}</h3>
                        </div>
                    </div>
                ))}
            </div>
        </SectionLayout>
    )
}

export default Skills