import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faInstagram, faLinkedin, faMedium, faTwitter } from '@fortawesome/free-brands-svg-icons'
// import { faCode } from '@fortawesome/free-solid-svg-icons';

// const currentYear = new Date().getFullYear();

const socialLinks = [
    { url: "https://www.linkedin.com/in/elchroy/", icon: faLinkedin, name: "linkedin" },
    // { url: "https://www.twitter.com/chijiokeroy", icon: faTwitter, name: "twitter" },   
    { url: "https://www.instagram.com/ceejay.roy", icon: faInstagram, name: "instagram" },   
    { url: "https://www.github.com/elchroy", icon: faGithub, name: "github" },   
    // { url: "https://medium.com/@chijiokeroy", icon: faMedium, name: "medium" },   
    // { url: "https://www.codementor.io/@celisha-wigwe", icon: faCode, name: "codementor" },   
];

const Socials = ({ className, size }) => {
    return (
        <ul className={className}>
            {socialLinks.map((link, i) => (
                <li className={link.name} key={i}>
                    <Link target="__blank" to={link.url}>
                        <FontAwesomeIcon size={size ?? "2x"} icon={link.icon} />
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default Socials