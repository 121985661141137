import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheck } from '@fortawesome/free-solid-svg-icons'
// import Socials from './Socials'

const SectionLayout = ({
    children,
    header: {
        id, classes, subtitle, title, description
    },
}) => {
    return (
        <div id={id} className={`${classes} border-bottom ptb-100 layout-section`}>
            <div className="container">
                <div className="section-title">
                    <span className="sub-title text-uppercase">{ subtitle }</span>
                    <h2>{ title }</h2>
                    <p>{ description ?? '' }</p>
                </div>
                { children }
            </div>
        </div>
    )
}

export default SectionLayout