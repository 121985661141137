import MainBanner from "./MainBanner"
import Skills from "./Skills"
import MyExperience from "./MyExperience"
import WorkPlaces from "./WorkPlaces"
import Articles from "./Articles"
import Portfolio from "./Portfolio"

const navSections = [
    {
        title: 'About',
        url: 'about',
        offset: 100,
        component: MainBanner
    },
    // {
    //     title: 'Experiences',
    //     url: 'experiences',
    //     offset: -1,
    //     component: MyExperience
    // },
    // {
    //     title: 'Companies',
    //     url: 'companies',
    //     offset: -1,
    //     component: WorkPlaces
    // },
    // {
    //     title: 'Articles',
    //     url: 'articles',
    //     offset: -1,
    //     component: Articles
    // },
    {
        title: 'Skills',
        url: 'skills',
        offset: -1,
        component: Skills
    },
    // {
    //     title: 'Projects',
    //     url: 'portfolio',
    //     offset: -1,
    //     component: Portfolio
    // },
];

export default navSections
