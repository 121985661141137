import React from 'react'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Heartbeat from '../App/Heartbeat'
// import CVLink from '../App/CVLink';
import navSections from "./sections"

const Navbar = () => {
    const [collapsed, setCollapsed] = React.useState(true)

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <nav id="navbar" className="navbar navbar-expand-md navbar-light fixed-top">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <Heartbeat className="light onhover" />
                    </Link>
                    
                    <button 
                        onClick={toggleNavbar}
                        className={classTwo} 
                        type="button" 
                        data-toggle="collapse" 
                        data-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                    >
                        <span className="icon-bar top-bar"></span>
                        <span className="icon-bar middle-bar"></span>
                        <span className="icon-bar bottom-bar"></span>
                    </button>

                    <div className={classOne} id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            {navSections.map((navLink, i) => (
                                <li className="nav-item" key={i}>
                                    <AnchorLink 
                                        offset={() => navLink.offset} 
                                        className="nav-link" 
                                        href={`#${navLink.url}`}
                                    >
                                        {navLink.title}
                                    </AnchorLink>
                                </li>
                            ))}
                        </ul>

                        <div className="side-nav">
                            {/* <CVLink /> */}
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}

export default Navbar
